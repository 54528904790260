<template>
  <section >
    <h2 class="titulo-links-escolha">CADASTRAR PLANEJAMENTO PEDAGÓGICO</h2>
    <div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="form-group col-md-8">
                    <label for="sel1" style="padding-top:15px">Selecione a etapa:</label>
                    <select class="form-control" v-model="planejamento.segmento_id" @change="listarSerie(planejamento.segmento_id)">
                         <option value="0" >Selecione a etapa</option>
                        <option v-for="n in segmentosALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-8">
                    <label for="sel1" style="padding-top:15px">Selecione o período:</label>
                    <select class="form-control" v-model="planejamento.serie_id" >
                        <option value="0" >Selecione o período</option>
                        <option v-for="n in serieALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="sel1" style="padding-top:15px">Selecione a Disciplina:</label>
                    <select class="form-control" v-model="planejamento.disciplina_id" >
                        <option v-for="n in disciplinaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>


                  <section style="margin-top:20px;">
                    <va-tabs v-model="nome_periodos">
                      <template #tabs>
                        <va-tab
                            v-for="tab in ['1° Periodo', '2° Periodo', '3° Periodo', '4° Periodo']"
                            :key="tab"
                          >
                            {{ tab }}
                          </va-tab>
                      </template>
                    </va-tabs>
                    <div class="table-responsive" v-if="nome_periodos == 1">
                      <div class="form-group col-md-12" style="padding-top:15px">
                          <h4>Planejar conteudo no 1° Periodo</h4>
                          <QuillEditor theme="snow" v-model:content="planejamento.periodo1_conteudo" contentType="html" style="min-height:200px;"  />
                      </div>
                    </div>
                    <div class="table-responsive" v-if="nome_periodos == 2">
                      <div class="form-group col-md-12" style="padding-top:15px">
                          <h4>Planejar conteudo no 2° Periodo</h4>
                          <QuillEditor theme="snow" v-model:content="planejamento.periodo2_conteudo" contentType="html" style="min-height:200px;"  />
                      </div>
                    </div>
                    <div class="table-responsive" v-if="nome_periodos == 3">
                      <div class="form-group col-md-12" style="padding-top:15px">
                          <h4>Planejar conteudo no 3° Periodo</h4>
                          <QuillEditor theme="snow" v-model:content="planejamento.periodo3_conteudo" contentType="html" style="min-height:200px;"  />
                      </div>
                    </div>
                    <div class="table-responsive" v-if="nome_periodos == 4">
                      <div class="form-group col-md-12" style="padding-top:15px">
                          <h4>Planejar conteudo no 4° Periodo</h4>
                          <QuillEditor theme="snow" v-model:content="planejamento.periodo4_conteudo" contentType="html" style="min-height:200px;"  />
                      </div>
                    </div>
                  </section>

                  <div class="flex" style="margin-top:20px;">
                    <va-button class="mr-2 mb-2"  @click="cadastrar"> {{ $t('Cadastrar') }}</va-button>
                    <va-button class="mr-2 mb-2" color="danger" @click="this.$router.push({name: 'planejamentoPedaAll'});">Voltar</va-button>
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </section >
</template>

<script>

import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";
import { DisciplinaEscolar } from "@/class/disciplina";
import { Calendario } from "@/class/calendario.js";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  components: {
    QuillEditor
  },
  name: 'planejamentopedagogico-cadastrar',
  data () {
    return {
      planejamento: {
        segmento_id:0,
        serie_id:0,
        disciplina_id:0,
        ano:null,
        mes: null,
        dia: null,
        conteudo: null,
        periodo1_conteudo:null,
        periodo2_conteudo:null,
        periodo3_conteudo: null,
        periodo4_conteudo:null,
      },
      datet:null,
      isLoading: false,
      segmentosALL:[],
      serieALL:[],
      disciplinaALL:[],
      nome_periodos:1,
    }
  },
  methods: {
    async listarSegmento() {
      let data = await SegmentoEscolar.obtemTodos();
      this.segmentosALL = data.data;
    },
    async listarSerie(seg) {
      let data = await SeriesEscolar.buscaPorSegmento(seg);
      this.serieALL = data.data;
    },
    async listarDisciplina() {
      let data = await DisciplinaEscolar.obtemTodos();

      for (const el of data.data) {
        if(el.id != 1){
          this.disciplinaALL.push(el);
        }
      }
    },
    async cadastrar() {
      try {

        if (this.planejamento.segmento_id == 0) {
          this.$vaToast.init({
            message: "Selecione uma etapa!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            color:'warning',
            fullWidth: false,
          });
          return false;
        }
        if (this.planejamento.serie_id == 0) {
          this.$vaToast.init({
            message: "Selecione um período!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            color:'warning',
            fullWidth: false,
          });
          return false;
        }
        if (this.planejamento.serie_id == 0) {
          this.$vaToast.init({
            message: "Selecione uma disciplina!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            color:'warning',
            fullWidth: false,
          });
          return false;
        }
        const data = await PlanejamentoPedagogico.cadastrar(this.planejamento);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        });
        this.$router.push({name: 'planejamentoPedaAll'});
      }
      catch(e) {
        // console.log(e);
      }
    },
  },
  async beforeMount() {
    let data = await Calendario.calendarioAtual();
    this.planejamento.ano = data.data.ano;

    await this.listarSegmento();
    await this.listarDisciplina()
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
